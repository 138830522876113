<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="敏感词:">
                    <el-input v-model="queryParams.content" placeholder="请输入敏感词" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column label="敏感词" prop="content" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="150">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="400" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button type="text" icon="el-icon-edit" @click="remove(scope.$index, scope.row)">删除
                        </el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="敏感词" prop="content">
                    <el-input v-model="form.content" placeholder="请输入敏感词"></el-input>
                </el-form-item>
                <el-form-item label="业务方" prop="ty" v-if="!business">
                    <el-select v-model="form.ty" placeholder="选择业务方" multiple>
                        <el-option v-for="items in businessList" :key="items.id" :label="items.name" :value="items.ty">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="级别" prop="level" v-if="mode==='add'">
                    <el-radio-group v-model="form.level">
                        <el-radio :label="1">初级</el-radio>
                        <el-radio :label="2">中级</el-radio>
                        <el-radio :label="3">高级</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 删除 -->
        <el-dialog title="是否删除数据" :visible.sync="removeVisible" width="300px" center>
            <div class="del-dialog-cnt">删除后数据无法恢复，是否确认删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="removeVisible = false">取 消</el-button>
                <el-button type="primary" @click="removeData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
export default {
    name: 'SensitiveWords',
    components: {
        Breadcrumb,
        Pagination,
    },
    data() {
        return {
            menuList: ['功能', '业务方敏感词'],
            queryParams: {
                page: 1,
                pagesize: 10
            },
            id: 0,
            businessList: [],
            //验证规则
            rules: {
                content: [{
                    required: true,
                    message: '敏感词不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                level: [{
                    required: true,
                    message: '级别不能为空',
                    trigger: 'blur'
                }],
                ty: [{
                    required: true,
                    message: '业务方不能为空',
                    trigger: 'blur'
                }]
            },
            business: true,
            loading: false,
            pageInfo: {},
            addVisible: false,
            removeVisible: false,
            confirmVisible: false,
            confirmContent: '',
            title: '加载中',
            mode: 'add',
            form: {

            },
        };
    },
    created() {
        this.loadAllData();
        this.loadBusinessData()
    },
    methods: {
        //敏感词列表
        loadAllData() {
            this.loading = true;
            request.get('/filter/keyword/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //业务方列表
        loadBusinessData() {
            request.get('/filter/business/list', { page: 1, pagesize: -1, state: 2 }).then(ret => {
                if (ret.code == 1) {
                    this.businessList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery(val) {
            this.queryParams.page = 1;
            this.loadAllData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadAllData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadAllData();
        },
        //新增
        add() {
            this.mode = 'add';
            this.title = '新增';
            this.form = {
                state: 2,
                level: 1
            };
            this.business = false
            this.addVisible = true;
        },
        //编辑
        edit(index, row) {
            this.business = true
            this.mode = 'edit';
            this.title = '编辑';
            this.form = Object.assign({}, row);
            this.addVisible = true;
        },
        // 删除
        remove(index, row) {
            this.id = row.id;
            this.removeVisible = true;
        },
        removeData() {
            request.post('/filter/keyword/remove', { id: this.id }).then(ret => {
                if (ret.code == 1) {
                    this.removeVisible = false;
                    this.loadAllData();
                    this.$message.success('删除成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                let params = Object.assign({}, this.form)
                if (params.ty) {
                    params.ty = params.ty.join(',')
                }
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/filter/keyword/add' : '/filter/keyword/edit';
                        //处理时间
                        console.log(5555, params)
                        request.post(url, params).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadAllData();
                                this.ty = []
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/filter/keyword/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadAllData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
};
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>